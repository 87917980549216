.App {
    font-family: sans-serif;
    text-align: center;
  }
  

  
.card .senadorimg {
  width: 50%;
  align-self: center;
}